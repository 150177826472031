@media (max-width: 576px) {
  //button's width when is mobile (list)
  .ibf-submit-button-list {
    width: 45px !important;
    border-radius: 4px !important;
  }

  //button's width when is mobile (card)
  .ibf-submit-button-card {
    width: 70px !important;
    border-radius: 4px !important;
  }
}

@media (min-width: 768px) {
  .ibf-text-result {
    font-family: Lato;
    font-style: normal;
    font-weight: bold;
    font-size: 33px;
    line-height: 40px;
    color: $ibf-color-gray;
  }
  .ibf-stem-desktop {
    width: 6vw;
    overflow: auto;
  }
}

//Max width
@media (max-width: 768px) {
  .ibf-max-width {
    max-width: 20vw;
  }
  .ibf-stem-mobile {
    width: 20vw;
    overflow: auto;
  }
  .ibf-text-result-small {
    font-family: Lato;
    font-style: normal;
    font-weight: bold;
    font-size: 23px;
    line-height: 25px;
    color: $ibf-color-gray;
  }
}

// border for list
.ibf-border {
  border: 1px solid #e7e2df;
  border-radius: 5px;
}

// may be deleted--
.ibf-text-card {
  font-family: Lato;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 19px;
  color: $ibf-color-gray;
}

//text for the NavBar
.ibf-text-navbar {
  font-family: Lato;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: $ibf-color-gray;
}

//delete Outline button
.ibf-no-outline {
  outline: none !important;
}

//text for result component
.ibf-link {
  color: #01a2e4;
  font-weight: bold;
  text-decoration: underline;
}

.ibf-text-detail-modal {
  font-family: Lato;
  font-style: normal;
  font-weight: bold;
  font-size: 33px;
  line-height: 40px;
  color: $ibf-color-gray;
}

//list form
.ibf-text-info {
  font-family: Lato;
  font-style: normal;
  font-weight: bold;
  font-size: 15px;
  line-height: 18px;
  color: #282c30;
}

.ibf-text-toast {
  &-success {
    color: green;
    fontstyle: normal;
    fontsize: 19px;
    lineheight: 23px;
    font-weight: bold;
  }
}
