$purple: #383eb3;
$gray: #fafafa;
$font-size-sm: 0.85rem;
$font-size: 1rem;
$font-size-md: 1.125rem;
$font-size-lg: 1.5rem;
$black: #44566C;
$font-size-text-mobile: '14px';
$font-line-height: '16px';

$ibf-bp-xl: 1200px;
$ibf-bp-lg: 992px;
$ibf-bp-md: 768px;
$ibf-bp-sm: 576px;
$ibf-bp: 576px;

$ibf-color-gray: #4a4a4a;

$theme-colors: (
  'primary': $purple,
);

$font-family-base: 'Lato', sans-serif;
