.text-ellipsis {
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
@font-face {
  font-family: Roboto;
  src: url(../Assets/Fonts/Roboto-Regular.ttf);
}
@font-face {
  font-family: 'Roboto Light';
  src: url(../Assets/Fonts/Roboto-Light.ttf);
}
