.step-main-title{
  font-weight: bold;
  font-size: 50px;
  line-height: 59px;

  @media (max-width: 768px) {
    font-size: 33px;
    line-height: 39px;
  }
}

.step-function-image{
  width: 355.01px;
  height: 277.67px;
}

.paragraph-title-step{
  font-weight: bold;
  font-size: 40px;
  line-height: 55px;

  @media (max-width: 768px) {
    font-size: 23px;
    line-height: 27px;

  }
}

.paragraph-step{
  font-weight: 300;
  font-size: 30px;
  line-height: 45px;
  font-family: 'Roboto Light';

  @media (max-width: 768px) {
    font-weight: 300;
    font-size: 21px;
    line-height: 31px;

  }


}
