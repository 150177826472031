.main-image-container {
  background-image: url(../../Assets/Images/oval.svg);
  color: white;
  height: 932px;
  background-repeat: no-repeat;
  background-size: cover;
}

.family {
  &-oval {
    width: 704.36px;
    height: 773.31px;
    @media (max-width: 1200px) and (min-width: 768px) {
      width: 673.36px;
      height: 510.31px;
      &-purple {
        height: 180px;
      }
    }
    @media (max-width: 768px) {
      &-purple {
        height: 122px;
        position: relative;
      }
    }
  }

  &-oval-position {
    left: 679px;
    top: 203px;

    @media (max-width: 1200px) and (min-width: 1024px) {
      left: 342px;
      top: 143px
    }
    @media (max-width: 1023px) and (min-width: 900px) {
      left: 334px;
      top: 230px;
    }

    @media (max-width: 899px) and (min-width: 780px) {
      left: 417px;
      top: 256px
    }

    @media (max-width: 779px) and (min-width: 768px) {
      left: 417px;
      top: 276px
    }
  }

}

.main {
  &-title {
    font-weight: 900;
    font-size: 65px;
    line-height: 76px;

    @media (max-width: 1200px) and (min-width: 768px) {
      font-weight: 900;
      font-size: 44px;
      line-height: 34px;
    }

    @media (max-width: 767px) {
      font-weight: 900;
      font-size: 44px;
      line-height: 52px;
    }
  }

  &-subtitle {
    font-weight: 300;
    font-size: 35px;
    line-height: 47px;
    font-family: Roboto, serif;
    font-style: normal;
    letter-spacing: -0.74px;

    @media (max-width: 1200px) and (min-width: 768px) {
      font-weight: 300;
      font-size: 21px;
      line-height: 25px;
    }

    @media (max-width: 767px) {
      font-weight: 300;
      font-size: 21px;
      line-height: 25px;
    }


  }

  &-link {
    font-size: 17px;
    line-height: 47px;
    cursor: pointer;
  }

  &-image-container {
    @media (max-width: 1200px) and (min-width: 768px) {
      height: 621px;
    }

    @media (max-width: 1023px) and (min-width: 768px) {
      height: 633px;
    }

    @media (max-width: 767px) and (min-width: 420px) {
      height: 540px;
    }

    @media (max-width: 420px) {
      height: 453px;
    }
  }
}

.container {
  &-oval {
    padding-top: 43px;
    padding-left: 60px;
    top: -81px;

    @media (max-width: 1200px) and (min-width: 768px) {
      padding-top: 43px;
      padding-left: 60px;
      top: -48px;
    }

    @media (max-width: 767px) {
      top: 0;
    }

    @media (max-width: 420px) {
      padding-top: 43px;
      padding-left: 60px;
    }
  }

  &-search {
    width: 25%;

    @media (max-width: 1200px) and (min-width: 768px) {
      width: 36%;
    }
    @media (max-width: 767px) {
      width: 50%;
    }
    @media (max-width: 460px) {
      width: 80% !important;
    }
  }
}

.custom-border {
  border-bottom-right-radius: 24px;
  border-top-right-radius: 24px;
}

.input-search {
  height: 47px;
  font-size: 13px;
  @media (max-width: 460px) {
    width: 110% !important;
  }

  &::placeholder {
    color: #1D3493;
    opacity: 0.62;
  }
}

.input-search-nav {
  height: 50px;
  width: 370px;
  font-size: 13px;

  &::placeholder {
    color: #1D3493;
    opacity: 0.62;
  }
}


@media (max-width: 420px) {

  .main {
    &-image-container {
      height: 531px;
      background-position: -75px;
    }
  }
  .container {
    &-oval {
      padding-top: 73px;
      padding-left: 30px;
      top: 0;
    }

    &-search {
      width: 80%;
    }
  }
}



