.waves-footer{
  z-index: 1024;
  top: 3811px;
  @media (min-width: 560px){
    display: none !important;
  }
  @media (max-width: 559px) and (min-width: 535px){
    top: 3571px;
  }

  @media (max-width: 534px) and (min-width: 520px){
    top: 3631px;
  }

  @media (max-width: 519px) and (min-width: 490px){
    top: 3663px;
  }

  @media (max-width: 489px) and (min-width: 475px){
    //top: 3702px;
    top: 3796px;
  }

  @media (max-width: 474px) and (min-width: 441px){
    top: 3736px;
  }

  @media (max-width: 440px) and (min-width: 431px){
    top: 3771px;
  }

  @media (max-width: 430px) and (min-width: 401px){
    top: 3866px;
  }

  @media (max-width: 400px) {
    top: 3943px;
  }

  &-web{
    @media (min-width: 768px){
      position: relative;
      top: -157px;
      margin-bottom: -5rem;
    }
  }
}
