.cart-dropdown {
  font-size: 1rem;
  line-height: 1.18;
  margin-right: 1.5rem;
  &__icon {
    font-size: 1.5rem;
    margin-right: 0.25rem;
    position: relative;
  }

  &__icon-total {
    position: absolute;
    top: -1px;
    right: -4px;
    background: pink;
    padding: 0 0.125rem;
    font-size: 0.625rem;
    line-height: 1.3;
    color: #eff6ff;
    border: solid 1px #eff6ff;
    text-align: center;
    min-width: 1rem;
  }
}

//scroll for popOver
.ibf-cart-items {
  max-height: 50vh;
  overflow: auto;
}
