.ibf-nav-shadow {
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.1) !important;
}
//search bar
.ibf-rounded-left {
  border-radius: 21px 0px 0px 21px !important;
}
.ibf-rounded-right {
  border-radius: 0px 21px 21px 0px !important;
  border-top: solid 1px #e7e2df !important;
  border-right: solid 1px #e7e2df !important;
  border-bottom: solid 1px #e7e2df !important;
}

//search-bar size

@media (max-width: 768px) {
  .ibf-search-size {
    min-width: 100% !important;
  }
}

.ibf-nav-menu {
  border: 1px solid rgb(231, 226, 223);
  border-radius: 22px !important;
  font-weight: bold;
  min-width: 80px;
  color: $ibf-color-gray;
}

.ibf-notification {
  &-cart {
    background: #e83293;
    top: 12px;
    right: 5px;
    border: 1px solid #f8f5f3;
    min-width: 26px;
    border-radius: 50%;
    position: absolute;
    color: #ffffff;
    font-family: Lato;
    font-style: normal;
    font-weight: bold;
    font-size: 13px;
    line-height: 24px;
  }

  &-filter {
    color: #ffffff;
    background: #23a2e4;
    border-radius: 50%;
    border: 1px solid #f8f5f3;
    font-family: Lato;
    font-style: normal;
    font-weight: bold;
    font-size: 13px;
    line-height: 24px;
    min-width: 26px;
    position: absolute;
    right: 0;
    bottom: 15px;
  }
}
