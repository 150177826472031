@import 'src/Styles/variables';

.document-section-main-title{
  font-weight: bold;
  font-size: 48px;
  line-height: 59px;

  @media (max-width: 768px) {
    font-size: 33px;
    line-height: 39px;
  }
}

.document-card-title{
  font-size: 26px;
  line-height: 38px;
  color: #FFFFFF;
}

.document-card-subtitle{
  font-size: 15px;
  line-height: 24px;
  font-weight: 300;
  color: #FFFFFF;
  &:hover{
    color: #ececec;
  }
}

.document-card-items-text{
  font-size: 17px;
  line-height: 21px;
  color: $purple;
  &:hover{
    cursor: pointer;
    text-decoration: underline;
  }
}
