select {
  -webkit-appearance: none;
  -moz-appearance: none;
  -ms-appearance: none;
  appearance: none;
  border: 1px solid #e7e2df;
  background: white;
  background-image: none;
}
/* Remove IE arrow */
select::-ms-expand {
  display: none;
}
/* Custom Select */
.select {
  position: relative;
  display: flex;
  color: #4a4a4a;
  line-height: 3;
  border-radius: 4px;
  align-content: center;
}
select {
  flex: 1;
  padding-left: 0.25em;
  cursor: pointer;

  font-family: Lato;
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 18px;
  color: #282c30;
}
/* Arrow */
.select::after {
  content: "\25BC";
  position: absolute;
  font-size: 8px;
  top: 0;
  right: 0;
  height: 3.8em;
  align-items: center;
  justify-content: center;
  display: flex;

  padding: 0 0.5em;
  background: white;
  cursor: pointer;
  border: 1px solid #e7e2df;
  border-top-right-radius: 0.25em;
  border-bottom-right-radius: 0.25em;
  pointer-events: none;
  -webkit-transition: 0.25s all ease;
  -o-transition: 0.25s all ease;
  transition: 0.25s all ease;
}
/* Transition */
.select:hover::after {
  color: #4a4a4a;
}
